require('dotenv').config();

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyDCU768aRtOK3iH_AgKIO1vZF6A2zaWC7k",
  authDomain: "lauron-a8b59.firebaseapp.com",
  projectId: "lauron-a8b59",
  storageBucket: "lauron-a8b59.appspot.com",
  messagingSenderId: "763688067261",
  appId: "1:763688067261:web:eae4249d69f8b2bd8717d4",
  measurementId: "G-ST6019K6JL"
};





  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const app = firebase.app()


export { firebase, auth, db, storage, app };